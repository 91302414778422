import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				АвтоПрокат Плюс - Зручна оренда автомобілів для ваших потреб
			</title>
			<meta name={"description"} content={"Ласкаво просимо до АвтоПрокат Плюс! Пропонуємо зручний сервіс оренди автомобілів для подорожей і бізнесу. Великий вибір авто, доступні ціни та якісне обслуговування."} />
			<meta property={"og:title"} content={"АвтоПрокат Плюс - Зручна оренда автомобілів для ваших потреб"} />
			<meta property={"og:description"} content={"Ласкаво просимо до АвтоПрокат Плюс! Пропонуємо зручний сервіс оренди автомобілів для подорожей і бізнесу. Великий вибір авто, доступні ціни та якісне обслуговування."} />
			<meta property={"og:image"} content={"https://silverleaft.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://silverleaft.com/img/6969347.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://silverleaft.com/img/6969347.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://silverleaft.com/img/6969347.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://silverleaft.com/img/6969347.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://silverleaft.com/img/6969347.png"} />
			
			
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-3">
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="left"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Контакти
			</Text>
			<Text
				margin="16px 0px 50px 0px"
				font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
				lg-margin="0px 0 50px 0px"
				text-align="left"
				sm-margin="0px 0 35px 0px"
				color="#60666d"
				lg-max-width="920px"
			>
				Зв'яжіться з нами для отримання додаткової інформації про послуги оренди авто. Ми завжди раді допомогти вам.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
					Адреса
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
					вул. Єршова, 11, офіс 511, <br/>м. Луцьк, Волинська область, Україна <br/>43026
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
					Телефон
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
					067 120 72-18
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 18px"
					border-width="0 0 0 2px"
					border-style="solid"
					border-color="--color-lightD2"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
						Email
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" color="#60666d">
					contact-us@silverleaft.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});